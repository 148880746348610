<template>
  <v-container fluid fill-height justify="center">
    <v-row justify="center" class="pa-0 pt-6">
      <v-col cols="12" sm="10" md="8">
        <v-row class="pa-0">
          <v-col v-for="(child, index) in items" :key="index" cols="12" md="4" sm="6">
            <v-card elevation="10">
              <v-list-item three-line :to="{ name: child.to, params: child.params }">
                <v-avatar rounded class="ma-3">
                  <v-icon size="50" color="#CAAC79">
                    {{ child.icon }}
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pa-0" v-text="child.name" />
                  <v-list-item-subtitle class="pa-0">
                    <small>{{ child.description }}</small>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
export default {
  components: {},
  data: () => ({
    categories: [],
    menu: {
      Employees: [
        {
          id: 1,
          name: "My tasks",
          description: "Services",
          icon: "mdi-order-bool-ascending-variant",
          to: "MyTasks",
          color: "white",
        },
      ],
      Providers: [
        {
          id: 21,
          name: "Proveedores",
          description: "Creear, editar o eliminar contactos de proveedores mayoristas",
          icon: "mdi-store-outline",
          color: "warning",
          to: "Proveedores",
        },
        {
          id: 46,
          name: "Catalogo Proveedores",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
      ],
      Supplier: [
        {
          id: 1,
          name: "Lista de Precios",
          description: "Creear, editar o eliminar productos de la lista de precios.",
          icon: "mdi-store-outline",
          color: "warning",
          to: "Proveedores",
        },
        {
          id: 2,
          name: "Clientes",
          description: "Creear, editar o eliminar clientes.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 3,
          name: "Pedidos",
          description: "Creear, editar o eliminar pedidos.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 4,
          name: "Mi perfil",
          description: "Editar perfil, logo, información de contacto.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
      ],
      Payroll: [
        {
          id: 0,
          name: "Clients",
          description: "Create, Edit, Delete Clients",
          icon: "mdi-store-marker-outline",
          to: "Clients",
          color: "white",
        },
        {
          id: 1,
          name: "Our people",
          description: "Create, Edit, Delete Employees",
          icon: "mdi-account-multiple-outline",
          to: "Employees",
          color: "white",
        },
        {
          id: 2,
          name: "Task table",
          description: "Create, Edit, Delete Employees",
          icon: "mdi-swap-horizontal",
          to: "ServicesSchedule",
          color: "white",
        },
        {
          id: 3,
          name: "Access Control",
          description: "Create, Edit, Delete Access Control",
          icon: "mdi-lock-check-outline",
          to: "Users",
          color: "white",
        },
        {
          id: 4,
          name: "Supplier",
          description: "Create, Edit, Delete Access Control",
          icon: "mdi-truck-cargo-container",
          color: "white",
          to: "Supplier",
        },
        {
          id: 5,
          name: "Services",
          description: "Create, Edit, Delete Servicess",
          icon: "mdi-truck-cargo-container",
          color: "white",
          to: "Services",
        },
      ],
      Owner: [
        {
          id: 0,
          name: "DashBoard",
          description: "Indicadores de la operación",
          icon: "mdi-finance",
          to: "DashBoard",
        },
        {
          id: 21,
          name: "Proveedores",
          description: "Creear, editar o eliminar contactos de proveedores mayoristas",
          icon: "mdi-store-outline",
          color: "white",
          to: "Proveedores",
        },
        {
          id: 46,
          name: "Catalogo Proveedores",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "white",
        },
        {
          id: 55,
          name: "Análisis de precios",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material_prices",
          color: "white",
        },
        {
          id: 58,
          name: "Análisis de Mejores precios",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material_supplier",
          color: "white",
        },
        {
          id: 42,
          name: "Clientes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Clients",
          color: "white",
        },
        {
          id: 43,
          name: "Editar menús",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-silverware-fork-knife",
          to: "QR4",
          color: "white",
        },

        {
          id: 45,
          name: "Nuestra gente",
          description:
            "Creear, editar o eliminar colaboradores, alidos, proveedores logísticos",
          icon: "mdi-account-multiple-outline",
          to: "Employees",
          color: "white",
        },
        {
          id: 44,
          name: "Admin Usuarios",
          description: "Creear, editar o eliminar cuentas de acceso a la plataforma.",
          icon: "mdi-account-lock-outline",
          to: "Users",
          color: "white",
        },

        {
          id: 87,
          name: "Mapa",
          description: "Mapa de la georeferenciación de restaurantes y proveedores.",
          icon: "mdi-map-marker-radius",
          color: "white",
          to: "ClientsMap",
        },
        {
          id: 88,
          name: "Tarjeta Digital",
          description: "QR con los datos de contacto personal.",
          icon: "mdi-card-account-details-outline",
          to: "BusinessCard",
          color: "white",
        },
        {
          id: 90,
          name: "Donde estamos?",
          description: "Donde esta nuestra gente.",
          icon: "mdi-map-marker-radius",
          color: "white",
          to: "mypeople",
        },
        {
          id: 91,
          name: "TPV",
          description: "Terminal punto de venta",
          icon: "mdi-map-marker-radius",
          color: "white",
          to: "tpv",
        },
        {
          id: 93,
          name: "Mailer",
          description: "Bulk Mailer",
          icon: "mdi-email-fast-outline",
          to: "Marketing",
        },
        {
          id: 94,
          name: "Puntos de Venta",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Pos",
          color: "warning",
        },
      ],
      Chef: [
        {
          id: 46,
          name: "Catalogo Proveedores",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 43,
          name: "Editar menús",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-silverware-fork-knife",
          to: "Chef_plates",
          color: "warning",
        },
        {
          id: 43,
          name: "Análsis de Costos por plato",
          description: "Creear, editar o eliminar Análsis de costos",
          icon: "mdi-silverware-fork-knife",
          to: "Chef_cost_analysis",
          color: "warning",
        },
      ],
    },
    items: [],
  }),
  methods: {
    get_company() {
      var qry = {
        table: "stores",
        filters: [{ field: "url", operator: "=", value: this.$store.getters.getHost }],
      };
      console.log(qry);
      this.$store.dispatch("setloading", true);
      webserver("get_table", qry, (data) => {
        console.log(data);
        this.$store.dispatch("setloading", false);
        if (data.length > 0) {
          this.$store.dispatch("setCompany", data[0]);
          this.logo = `https://${data[0].url}/logos/${data[0].url}/logo.png`;
        }
      });
    },
    openLink(e) {
      console.log(e);
      this.$router.push("/" + e + "/");
    },
    get_menu() {
      this.loading_status = true;
      var qry = {
        account: window.company.code,
        table: "fish_docs",
        filters: [
          {
            field: "account",
            operator: "=",
            value: window.company.code,
          },
        ],
      };
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        data.forEach((rg) => {
          rg.to = "FishEvents";
          rg.params = { code: rg.code, name: rg.name };
        });

        this.menu.Trout = data;
        this.items = this.menu[this.$store.state.profile.user_access];
        this.$store.dispatch("set_menuItems", this.items);
      });
    },
    get_items() {
      this.items_bygroup = null;
      var qry = {
        account: this.$store.state.profile.account,
        table: "stores",
        filters: [],
      };
      if (
        this.$store.state.profile.user_access === "Sales" ||
        this.$store.state.profile.user_access === "Promotor"
      ) {
        qry.filters.push({
          field: "agent",
          operator: "=",
          value: this.$store.state.profile.name,
        });
      }
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        data.forEach((rg) => {
          rg.date = new Date(rg.issue_date).toISOString();
          rg.qty = 1;
        });

        var now = new Date();
        var fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 30);

        var lastMonth = [];
        for (var d = new Date(fromDate); d <= now; d.setDate(d.getDate() + 1)) {
          var dtom = new Date(d);
          lastMonth.push(dtom.toISOString());
        }

        var result = [];
        data.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = { Id: value.date, qty: 0 };
            result.push(res[value.date]);
          }
          res[value.date].qty += value.qty;
          return res;
        }, {});
      });
    },
  },
  mounted() {
    this.get_menu();
    this.$store.dispatch("setNavStatus", true);
    this.$store.dispatch("set_menu", true);
  },
  watch: {
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.items = this.menu[newVal.user_access];
        this.$store.dispatch("set_menuItems", this.items);
        // console.log(this.items);
        // this.categories = Object.keys(this.items);
        //console.log(newVal);
      }
    },
  },
};
</script>

<style></style>
